input {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    max-width: 98% !important;
}

select {
  border-left: none !important;
  border-top: none !important;
  border-right: 7px solid transparent !important;
  outline: none !important;
}
